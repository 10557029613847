// paper & background
$paper: #ffffff;

// secondary
$secondaryLight: #eef2f6;
$secondaryMain: #2196f3;
$secondaryDark: #1e88e5;
$secondary200: #90caf9;
$secondary800: #1565c0;

// pink: {
//   100: "#",
//   200: "#d7a7a8",
//   300: "#",
//   400: "#af4e51",
//   600: "#7c1b1e",
//   700: "#",
//   800: "#",
//   900: "#1f0708"
// },
// primary
$primaryLight: #edcdcd;
$primaryMain: #9b2226;
$primaryDark: #5d1417;
$primary200: #c37a7d;
$primary800: #3e0e0f;

// success Colors
$successLight: #6ad01f;
$success200: #69f0ae;
$successMain: #56ca00;
$successDark: #4cb200;

// error
$errorLight: #ff6166;
$errorMain: #ff4c51;
$errorDark: #e04347;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #ffca64;
$warningMain: #ffb400;
$warningDark: #e09e00;

// silver
$silverLight: #9c9fa4;
$silverMain: #8a8d93;
$silverDark: #777b82;

// grey
$grey50: #f8fafc;
$grey100: #eef2f6;
$grey200: #e3e8ef;
$grey300: #cdd5df;
$grey500: #697586;
$grey600: #4b5565;
$grey700: #364152;
$grey900: #121926;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// Secondary dark
$darkSecondaryLight: #eef2f6;
$darkSecondaryMain: #2196f3;
$darkSecondaryDark: #1e88e5;
$darkSecondary200: #90caf9;
$darkSecondary800: #1565c0;

// Primary dark
$darkPrimaryLight: #d1c4e9;
$darkPrimaryMain: #7c4dff;
$darkPrimaryDark: #651fff;
$darkPrimary200: #b39ddb;
$darkPrimary800: #6200ea;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

// ==============================|| JAVASCRIPT ||============================== //

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primary800: $primary800;

  // secondary
  secondaryLight: $secondaryLight;
  secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary800: $secondary800;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // silver
  silverLight: $silverLight;
  silverMain: $silverMain;
  silverDark: $silverDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey900: $grey900;

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  // darkPaper: $darkPaper;
  // darkBackground: $darkBackground;
  darkPaper: #212946;
  darkBackground: #29314f;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;
}
